<!-- =========================================================================================
  File Name: UserList.vue
  Description: User List page
========================================================================================== -->

<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <vs-table
        :data="usersLoading ? [] : sortedData"
        stripe
        hoverFlat
        :noDataText="usersLoading ? $t('Loading') : $t('NoRecordsFounded')"
        class="userTable vs-con-loading__container"
        id="table-with-loading"
      >
        <template slot="header">
          <vs-button
            icon-after
            icon-pack="feather"
            icon="icon-user-plus"
            size="medium"
            class="mb-4 md:mb-0 add-user"
            color="success"
            @click="openAddUser()"
          >
            {{ $t("AddUser") }}
          </vs-button>
          <vs-input
            :placeholder="$t('SearchContact')"
            icon-pack="feather"
            :icon="!searchQuery.length ? 'icon-search' : 'icon-x'"
            icon-after
            v-model="searchQuery"
            @keyup.enter="searchOperator"
            v-on:icon-click="
              searchQuery.length ? (searchQuery = '') : '';
              searchOperator();
            "
          />
        </template>
        <template slot="thead">
          <vs-th sort-key="name">{{ $t("Name") }}</vs-th>
          <vs-th sort-key="email">{{ $t("Email") }}</vs-th>
          <vs-th sort-key="status">{{ $t("Status") }}</vs-th>
          <vs-th v-if="hasInbox" sort-key="operatorDataIntentions">{{
            $t("Intentions")
          }}</vs-th>
          <vs-th v-if="hasInbox" sort-key="operatorDataEntities">{{
            $t("EntityKey")
          }}</vs-th>
          <vs-th v-if="hasInbox" sort-key="operatorDataAcl">{{
            $t("Type")
          }}</vs-th>
          <vs-th
            v-if="hasInbox && inbox.operatorQueue"
            sort-key="operatorDataInQueue"
            >{{ $t("ServiceQueue") }}</vs-th
          >
          <vs-th>{{ $t("CardActions") }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in data"
            :class="tr.isDisabled ? 'userDisabled' : ''"
          >
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{
              tr.name
            }}</vs-td>
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{
              tr.email
            }}</vs-td>
            <vs-td>
              <vs-chip
                transparent
                :color="
                  tr.status === 1
                    ? 'success'
                    : tr.status === 2
                    ? 'warning'
                    : 'primary'
                "
              >
                {{ getStatusName(tr) }}
              </vs-chip>
            </vs-td>
            <vs-td v-if="hasInbox" class="truncate max-w-xs whitespace-nowrap">
              <span>
                {{
                  tr.operatorDataIntentions.includes("__all__")
                    ? $t("All")
                    : intentionsOrEntitiesString(
                        tr.operatorDataIntentions,
                        "intentions"
                      )
                }}
              </span>
            </vs-td>
            <vs-td v-if="hasInbox" class="truncate max-w-xs whitespace-nowrap">
              <span>{{
                !tr.operatorDataEntities.length
                  ? $t("All")
                  : intentionsOrEntitiesString(
                      tr.operatorDataEntities,
                      "entities"
                    )
              }}</span>
            </vs-td>
            <vs-td v-if="hasInbox">{{ tr.acl || tr.operatorDataAcl }}</vs-td>
            <vs-td v-if="hasInbox && inbox.operatorQueue">
              {{ tr.operatorDataInQueue ? $t("Yes") : $t("No") }}
            </vs-td>
            <vs-td>
              <CellRendererActions
                :userData="tr"
                @toggle-edit="openEdit(tr)"
                @toggle-disable="openDisable(tr._id, tr.isDisabled)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        v-if="users.length > 0"
        class="mt-4"
        :total="Math.ceil(total / 10)"
        v-model="currentPage"
        @click="currentPage++"
      />
    </div>

    <vs-popup
      :title="!isDisabled ? 'Desativar usuário' : 'Ativar usuário'"
      :active.sync="popupDisableUser"
    >
      <vs-row>
        <vs-col vs-type="flex" vs-align="center" vs-justify="center">
          <vs-icon
            icon-pack="feather"
            icon="icon-alert-triangle"
            size="1.7rem"
            color="warning"
          />
          <h3 class="ml-3">Atenção!</h3>
        </vs-col>
        <vs-col class="mt-5">
          <p v-if="!isDisabled" class="text-center text-xl text-grey">
            Ao <b>desativar</b> esse usuário, os atendimentos atuais deste
            atendente<br />
            serão enviados para fila de atendimento, ele não aparecerá na<br />
            transferência e não irá receber ou criar mais nenhum atendimento<br />
            até ser reativado.
          </p>
          <p v-else class="text-center text-xl text-grey">
            Ao <b>ativar</b> esse usuário, ele voltará receber novos<br />
            atendimentos ou transferências.
          </p>
        </vs-col>
        <vs-col v-if="!isDisabled" class="mt-5">
          <vs-alert
            :active="popupDisableUser"
            icon-pack="feather"
            icon="icon-star"
          >
            <b>Dica:</b> Função ideal para férias ou recessos longos de algum
            usuário.
          </vs-alert>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" class="mt-5">
          <vs-button
            icon-pack="feather"
            icon="icon-check"
            icon-after
            @click="disableUser"
            >{{ $t("Confirm") }}</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      :title="!isEditing ? $t('NewUser') : $t('Edit')"
      :active.sync="popupUser"
      :key="popupUserKey"
    >
      <vs-row>
        <vs-col v-if="!isEditing">
          <p>{{ $t("InviteUser") }}</p>
        </vs-col>
        <vs-col v-if="!isEditing" class="mt-3">
          <vs-input
            name="email"
            v-model="email"
            type="email"
            class="w-full"
            :label="$t('Email')"
          />
        </vs-col>
        <vs-col class="mt-3" v-if="acl">
          <small>{{ $t("Type") }}</small>
          <v-select
            id="type"
            v-model="acl"
            :clearable="false"
            :options="aclOptions"
            name="type"
            class="w-full"
          />
        </vs-col>
        <vs-col v-if="isEditing" class="mt-3">
          <vs-input
            name="Nome"
            v-model="userName"
            type="name"
            class="w-full"
            label="Nome"
            :disabled="!isAdmin || !$acl.check('operator')"
          />
        </vs-col>
        <vs-col v-if="hasInbox" class="mt-3">
          <small>{{ $t("Intention") }}</small>
          <v-select
            v-model="intentions"
            :clearable="false"
            :options="inboxIntentions"
            multiple
            name="intentions"
            class="w-full"
          />
        </vs-col>
        <vs-col
          v-if="inboxEntities && inboxEntities.length > 0 && hasInbox"
          class="mt-3"
        >
          <small>{{ $t("KeyEntities") }}</small>
          <v-select
            id="entities"
            v-model="entities"
            :clearable="false"
            :options="inboxEntities"
            multiple
            name="entities"
            class="w-full"
          />
        </vs-col>
        <vs-col v-if="operatorQueue && hasInbox" class="mt-3">
          <small>Na Fila?</small>
          <vs-switch color="#28c76f" v-model="inQueue" />
          <vs-input
            v-if="inQueue"
            name="conversationsLimit"
            v-model="conversationsLimit"
            type="number"
            class="w-full my-3"
            :label="$t('ConversationsLimit')"
            min="1"
            max="999"
            @change="
              conversationsLimit =
                conversationsLimit > 999
                  ? 999
                  : !conversationsLimit
                  ? null
                  : conversationsLimit < 1
                  ? 1
                  : conversationsLimit
            "
          />
        </vs-col>
        <vs-col
          v-if="
            ($acl.check('admin') || $acl.check('master')) &&
            isEditing &&
            hasInbox
          "
          class="mt-3"
        >
          <vs-tooltip
            :active="userAclMaster"
            text="Sem permissão para alterar senha deste usuário"
          >
            <vs-input
              name="newpassword"
              v-model="password"
              type="text"
              class="w-full"
              :label="$t('NewPassword')"
              :disabled="userAclMaster"
            />
          </vs-tooltip>
        </vs-col>
        <vs-col v-if="arrayOperatorsIds.length" class="mt-3">
          <small>ID Operador Integração</small>
          <div
            v-for="(item, index) in arrayOperatorsIds"
            :key="index"
            class="w-full mb-3"
          >
            <small>{{ item.name }}</small>
            <input class="custom-input" v-model="item.value" />
          </div>
        </vs-col>
        <vs-col class="mt-5" vs-type="flex" vs-justify="end">
          <vs-button
            id="editUserCreateUserButton"
            icon-pack="feather"
            icon-after
            :icon="isEditing ? 'icon-save' : 'icon-user-plus'"
            @click="isEditing ? editUser() : createUser()"
            class="vs-con-loading__container"
          >
            {{ isEditing ? $t("Save") : $t("Add") }}
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import CellRendererActions from "@/views/apps/acc/user-list/cell-renderer/CellRendererActions.vue";

export default {
  components: {
    CellRendererActions,
    "v-select": vSelect,
  },
  data() {
    return {
      userAclMaster: false,
      acl: { label: "", value: "" },
      aclOptions: [
        {
          label: "Admin",
          value: "admin",
        },
        {
          label: this.$t("Manager"),
          value: "manager",
        },
        {
          label: this.$t("Operator"),
          value: "operator",
        },
      ],
      arrayOperatorsIds: [],
      conversationsLimit: null,
      currentPage: 1,
      currentUser: "",
      email: "",
      entities: [],
      idUserDisable: null,
      inbox: {},
      inboxEntities: [],
      inboxID: null,
      inboxIntentions: [],
      intentions: [],
      intentionsOptions: [],
      isDisabled: false,
      isEditing: false,
      name: "",
      operatorQueue: false,
      password: "",
      popupDisableUser: false,
      popupUser: false,
      popupUserKey: 1,
      searchQuery: "",
      status: null,
      total: 0,
      userName: "",
      usersLoading: true,
      componentKey: 0,
      inQueue: false,
    };
  },
  computed: {
    planData() {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters;
    },
    hasInbox() {
      return this.planData["inboxAvailable"];
    },
    users: {
      get() {
        return this.$store.state.acc.current_acc_users;
      },
      set(val) {
        this.$store.commit("acc/SET_CURRENT_ACC_USERS", val);
      },
    },
    isAdmin() {
      return (
        this.$store.state.user.userRole === "admin" ||
        this.$store.state.user.userRole === "master"
      );
    },
    sortedData() {
      return this.users.slice().sort((a, b) => {
        let comparison = 0;

        if (this.sortColumn) {
          const valueA = a.operatorData[this.sortColumn] || "";
          const valueB = b.operatorData[this.sortColumn] || "";

          if (valueA > valueB) {
            comparison = 1;
          } else if (valueA < valueB) {
            comparison = -1;
          }

          // Apply sort direction
          if (this.sortDirection === "desc") {
            comparison *= -1;
          }
        }

        return comparison;
      });
    },
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
  },
  methods: {
    getStatusName(item) {
      if (item.isDisabled) return this.$t("Inactive");
      if (item.status === 1) return this.$t("Active");
      if (item.status === 2) return this.$t("Inactive");
      return this.$t("Pending");
    },
    searchOperator() {
      this.getUsers();
    },
    updateUserStore(id, updatedFields) {
      // Get the current users array
      const users = [...this.users]; // Create a copy to avoid mutating directly

      // Find the index of the user to update
      const index = users.findIndex((user) => user._id === id);

      // If the user is found, update the user fields
      if (index !== -1) {
        users[index] = { ...users[index], ...updatedFields };

        // Update the users array in the store
        this.users = users; // Trigger the setter of the computed property
      }
    },
    getArrayOperatorsId() {
      this.$http
        .get(`/g/product/apps/list/operatorsId`, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          if (response.data.data.length) {
            this.arrayOperatorsIds = response.data.data;
          }
        });
    },
    openAddUser() {
      this.isEditing = false;
      this.acl = {};
      this.entities = [];
      this.intentions = [];
      this.currentUser = "";
      this.popupUser = true;
      this.password = "";
      this.inQueue = false;
      this.conversationsLimit = null;
    },
    openEdit(data) {
      this.entities = [];
      this.intentions = [];
      this.currentUser = data._id;
      this.password = "";
      this.conversationsLimit = null;
      this.isEditing = true;
      this.userAclMaster = !!data.master;

      this.userName = data.name || "";
      if (data.operatorDataEntities)
        data.operatorDataEntities.forEach((el) => {
          const element = this.inboxEntities.find(
            (el2) => el2.value === el || el2.label === el
          );
          this.entities.push(element || { label: el, value: el });
        });
      if (data.operatorDataIntentions)
        data.operatorDataIntentions.forEach((el) => {
          const element = this.inboxIntentions.find(
            (el2) => el2.value === el || el2.label === el
          );
          this.intentions.push(element || { label: el, value: el });
        });

      let index = this.entities.indexOf("__na__");
      if (index > -1) this.entities[index] = this.$t("NoneEntity");
      index = this.entities.indexOf("__all__");
      if (index > -1) this.entities[index] = this.$t("All");
      index = this.intentions.indexOf("__all__");
      if (index > -1) this.intentions[index] = this.$t("All");

      this.acl = this.aclOptions.find(
        (el) => data.operatorDataAcl === el.value
      );

      this.inQueue = data.operatorDataInQueue;
      this.conversationsLimit = data.operatorDataConversationsLimit || null;

      // preenche os ids que o operador já tem
      if (this.arrayOperatorsIds.length > 0) {
        for (let i = 0; i < this.arrayOperatorsIds.length; i++) {
          // limpa os valores de arrayOperatorsIds
          this.arrayOperatorsIds[i].value = null;
          if (data.operatorDataIntegrationIds)
            for (let j = 0; j < data.operatorDataIntegrationIds.length; j++) {
              if (
                this.arrayOperatorsIds[i].name ===
                data.operatorDataIntegrationIds[j].name
              ) {
                this.arrayOperatorsIds[i].value =
                  data.operatorDataIntegrationIds[j].value;
              }
            }
        }
      }
      this.popupUserKey += `popupUserKey_${Math.random()}`;
      this.popupUser = true;
    },
    createUser() {
      window.analytics.track(
        "Criar Usuário",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
      let acl = "";
      if (this.acl.value !== undefined) acl = this.acl.value;

      let entities = JSON.parse(JSON.stringify(this.entities));
      let intentions = JSON.parse(JSON.stringify(this.intentions));

      let index = entities.findIndex(
        (el) => el.label === this.$t("NoneEntity")
      );

      if (index > -1) entities[index].value = "__na__";
      index = entities.findIndex((el) => el.label === this.$t("All"));
      if (index > -1) entities.splice(index, 1);

      index = intentions.findIndex((el) => el.label === this.$t("All"));
      if (index > -1) intentions[index].value = "__all__";
      index = intentions.findIndex((el) => el.label === this.$t("Others"));
      if (index > -1) intentions[index].value = "";

      const payload = {
        userDetails: {
          acl: acl,
          intentions: intentions.map((el) => el.value),
          entities: entities.map((el) => el.value),
          email: this.email,
          acc: this.$store.state.acc.current_acc.id,
          inQueue: this.inQueue,
          conversationsLimit: this.conversationsLimit,
          integrationIds: this.arrayOperatorsIds,
        },
        notify: this.$vs.notify,
      };

      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(this.email)) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("InvalidEmail"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      this.$store
        .dispatch("auth/inviteUserJWT", payload)
        .then((response) => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Ok"),
            text: this.$t("UserInvitedSuccessfully"),
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
          this.$store.commit("acc/ADD_ACC_USER", {
            _id: response.data.data.id,
            name: response.data.data.name || "-",
            email: this.email,
            status: response.data.data.status,
            operatorDataAcl: acl,
            acl: acl,
            createdAt: new Date(),
            operatorData: {
              acc: this.$store.state.acc.current_acc.id,
              acl: acl,
              entities: entities.map((el) => el.value),
              inQueue: this.inQueue,
              intentions: intentions.map((el) => el.value),
              online: false,
              status: response.data.data.status,
              user: response.data.data.id,
              _id: response.data.data.operatorData._id,
            },
            inviteKey: response.data.data.inviteKey,
            operatorDataIntentions: intentions.map((el) => el.value),
            operatorDataEntities: entities.map((el) => el.value),
            operatorDataIntegrationIds: this.arrayOperatorsIds,
            inQueue: this.inQueue,
            conversationsLimit: this.conversationsLimit,
            lastLogin: new Date(),
          });

          this.email = "";
          this.entities = [];
          this.intentions = [];
          this.acl = "";
          this.popupUser = false;
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    async getUsers() {
      this.usersLoading = true;
      let url = `/g/acc/user/list?acc=${this.$store.state.acc.current_acc.id}&page=${this.currentPage}`;
      if (this.searchQuery) url += `&searchQuery=${this.searchQuery}`;
      try {
        const users = await this.$http.get(url);
        this.usersLoading = false;
        this.users = users.data.data.users;
        this.total = users.data.data.total;
      } catch (e) {
        this.usersLoading = false;
      }
    },
    editUser() {
      this.$vs.loading({
        container: "#editUserCreateUserButton",
        scale: 0.45,
      });

      let intentions = JSON.parse(JSON.stringify(this.intentions));
      let entities = JSON.parse(JSON.stringify(this.entities));

      let index = entities.findIndex(
        (el) => el.label === this.$t("NoneEntity")
      );

      if (index > -1) entities[index].value = "__na__";
      index = entities.findIndex((el) => el.label === this.$t("All"));
      if (index > -1) entities.splice(index, 1);

      index = intentions.findIndex((el) => el.label === this.$t("All"));
      if (index > -1) intentions[index].value = "__all__";
      index = intentions.findIndex((el) => el.label === this.$t("Others"));
      if (index > -1) intentions[index].value = "";
      this.$http({
        method: "PUT",
        url: `/g/acc/userAccess`,
        data: {
          user: this.currentUser,
          name: this.userName,
          acl: this.acl.value,
          intentions: intentions.map((el) => el.value),
          entities: entities.map((el) => el.value),
          acc: this.$store.state.acc.current_acc.id,
          inQueue: this.inQueue,
          conversationsLimit: this.conversationsLimit,
          password: this.password,
          integrationIds: this.arrayOperatorsIds,
        },
      })
        .then(() => {
          this.$vs.loading.close("#editUserCreateUserButton > .con-vs-loading");
          this.popupUser = false;
          this.$vs.notify({
            title: this.$t("Success"),
            text: this.$t("InformationChanged"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
          this.updateUserStore(this.currentUser, {
            name: this.userName,
            operatorData: {
              acl: this.acl.value,
              intentions: intentions.map((el) => el.value),
              entities: entities.map((el) => el.value),
              inQueue: this.inQueue,
              conversationsLimit: this.conversationsLimit,
              integrationIds: this.arrayOperatorsIds,
            },
            operatorDataIntentions: intentions.map((el) => el.value),
            operatorDataEntities: entities.map((el) => el.value),
            operatorDataInQueue: this.inQueue,
            operatorDataConversationsLimit: this.conversationsLimit,
            operatorDataIntegrationIds: this.arrayOperatorsIds,
            operatorDataAcl: this.acl.value,
          });
        })
        .catch((error) => {
          this.$vs.loading.close("#editUserCreateUserButton > .con-vs-loading");
          if (error.request != null)
            if (typeof error.request.response !== "undefined")
              error.message =
                JSON.parse(error.request.response).data != null
                  ? Array.isArray(JSON.parse(error.request.response).data)
                    ? JSON.parse(error.request.response).data[0].msg
                    : ""
                  : JSON.parse(error.request.response).message;
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    intentionsOrEntitiesString(data, type) {
      if (type === "intentions") {
        const replacements = {
          "": this.$t("Others"),
          __all__: this.$t("All"),
        };
        data = data.map((item) => replacements[item] || item);
      } else {
        const replacements = {
          "": this.$t("All"),
          __na__: this.$t("NoneEntity"),
        };
        data = data.map((item) => replacements[item] || item);
      }

      return data.join(", ");
    },
    openDisable(userID, isDisabled) {
      this.idUserDisable = userID;
      this.isDisabled = isDisabled;

      this.popupDisableUser = true;
    },
    async disableUser() {
      this.popupDisableUser = false;

      this.$vs.loading({
        container: "#table-with-loading",
        scale: 0.6,
      });

      const statusUrl = this.isDisabled ? "enable/" : "disable/";
      const response = await this.$http.put(
        "/g/acc/access/" +
          statusUrl +
          this.$store.state.acc.current_acc.id +
          "/" +
          this.idUserDisable
      );

      if (response.status === 200) {
        this.isDisabled = !this.isDisabled;
        this.updateUserStore(this.idUserDisable, {
          isDisabled: this.isDisabled,
        });

        this.$vs.notify({
          time: 2500,
          title: !this.isDisabled ? "Ativar usuário" : "Desativar usuário",
          text: !this.isDisabled
            ? "Usuário ativado com sucesso."
            : "Usuário desativado com sucesso.",
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        this.$vs.loading.close("#table-with-loading > .con-vs-loading");
      } else
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: response.message || this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
    },
  },
  mounted() {
    this.getArrayOperatorsId();
    // let searchInput = document.getElementsByClassName("vs-table--search-input");
    // searchInput[0].placeholder = this.$t("SearchUsers");

    this.getUsers();

    this.$http
      .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
      .then((response) => {
        const data = response.data.data.inbox;

        if (this.hasInbox) {
          this.inbox = data;
          this.operatorQueue = data.operatorQueue;
          this.inboxID = data._id;
          this.inboxIntentions = [
            { label: this.$t("All"), value: "__all__" },
            { label: this.$t("Others"), value: "" },
          ];
          if (data.intentions)
            data.intentions.forEach((el) => {
              if (
                this.inboxIntentions.findIndex((el2) => el2.label === el) === -1
              )
                this.inboxIntentions.push({
                  label: el,
                  value: el,
                });
            });

          this.inboxEntities = [
            { label: this.$t("NoneEntity"), value: "__na__" },
            { label: this.$t("All"), value: "" },
          ];
          if (data.keyEntities)
            data.keyEntities.forEach((el) => {
              this.inboxEntities.push({
                label: el,
                value: el,
              });
            });
        }
      });
  },
};
</script>

<style lang="scss">
.userTable .vs-table--header {
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}
.vs-table--search-input {
  border-radius: 5px;
}
.vuesax-app-is-ltr .vs-table--search {
  margin-left: 0;
}
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.custom-input {
  width: 100%;
  padding: 0.7rem !important;
  font-size: 1rem !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &:focus {
    border: 1px solid rgba(var(--vs-primary), 1) !important;
  }
}
.userTable .userDisabled td span {
  color: #d2d2d2;
}
</style>
